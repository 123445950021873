<style scoped lang="less">
@cus-primary-color: #2C56FF;
@cus-primary-color-shadow: #8AA1FF;
@cus-primary-color-tint: #E7EFFE;
@cus-primary-color-weight: #1E44EC;
.auth-block {
  display: flex;
}
.action-cate {
  width: 190px;
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 5px;
  overflow: hidden;
  li {
    line-height: 40px;
    cursor: pointer;
    padding: 0 10px;
    background-color: #FAFAFA;
    border-bottom: 1px solid #dcdee2;
    transition: all .3s;
    position: relative;
    &.active, &:hover {
      color: @cus-primary-color;
    }
    &.active {
      &::after {
        content: '';
        background-color: @cus-primary-color;
        width: 4px;
        height: 100%;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
}
.action-plane {
  margin-left: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  .action-values {
    display: flex;
    flex-direction: column;
    height: 200px;
    overflow: auto;
    position: relative;
    .check-all {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .action-seleced {
    overflow: auto;
    max-height: 200px;
    flex-wrap: wrap;
  }
}
</style>

<style lang="less">
.auth-user-page {
  .action-seleced {
    position: relative;
    .fm-tag {
      margin-left: 0;
      margin-right: 5px;
      margin-top: 5px;
    }
  }
  .action-values {
    .fm-checkbox-group.fm-checkbox-group-vertical > .fm-checkbox {
      margin-top: 0;
    }
  }
}
</style>

<template>
  <div class="auth-user-page">
    <base-sign-page
      title-text="用户"
      :note-text="noteText"
      :column-list="columnList"
      :data-list="dataList"
      @countDataChange="countDataChange"
      :count-fun="countFun"
      :show-search="true"
      :loading="loading"
      :title-menus="titleMenus"
      @clickTitleMenu="clickTitleMenu"
      @tableAction="tableAction"
      :table-actions="tableActions"
      :need-pagination="true">
    </base-sign-page>
    <fm-form-dialog
      form-title="用户"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      label-alone
      label-align="left"
      :old-data="chooseData"
      form-width="800px"
      @formSubmit="formSubmit"
      @handleClose="openDialog = false">
    </fm-form-dialog>
    <fm-modal v-model="authModalShow" theme="mh-blackt" width="900px">
      <div slot="header">{{chooseData ? (chooseData.name + '权限配置') : '未选择'}}</div>
      <div class="auth-block">
        <ul class="action-cate">
          <li v-for="cate in cates" :class="{active: actionCate.key === cate.key}" :key="cate.key" @click="actionCate = cate">{{cate.label}}</li>
        </ul>
        <div class="action-plane">
          <div class="action-seleced" style="margin-bottom: 10px;" v-if="actionSelected.length">
            <fm-tag closable @close="onRemove(selected)" v-for="selected in actionSelected" :key="selected.key">{{selected.label}}</fm-tag>
          </div>
          <div style="margin-bottom: 10px;">
            <fm-input-new v-model="searchKey">
              <i class="fmico fmico-search" slot="prefix"/>
            </fm-input-new>
            <fm-btn style="margin-left: 10px;" @click="selectAll">全选</fm-btn>
            <fm-btn @click="selectOff">反选</fm-btn>
          </div>
          <div class="action-values" v-loadingx="authLoading">
            <fm-checkbox-group vertical @change="valuesChange" :value="actionSelected.map(v => v.key)">
              <fm-checkbox v-for="values in actionValuesShow" :label="values.key" :key="values.key">{{values.label}}</fm-checkbox>
            </fm-checkbox-group>
          </div>
        </div>
      </div>
      <div slot="footer" class="modal-footer-btns">
        <fm-btn type="primary" tint @click="authModelSubmit" v-loadingx="authRunning">确定</fm-btn>
        <fm-btn @click="authModalShow = false" v-loadingx="authRunning">取消</fm-btn>
      </div>
    </fm-modal>
  </div>
</template>

<script>
import BaseSignPage from '@/components/base/BaseSignPage'

import {
  userRequest as request,
  ucenterRequest,
  resetPassword
} from '@/api'

// service 是服务，sys是系统，sys_instance是运行着的系统
// app 可以理解成我们写好的代码，逻辑啥的是确定的。
// app_service 是app部署到某个服务器上运行的服务。
// app_service 是服务，sys是系统，sys_instance是运行着的系统
const cates = [
  { key: 'sys', label: '系统', api: 'loadSysList' },
  { key: 'server', label: '服务器', api: 'loadServerList' },
  { key: 'app', label: '应用', api: 'loadAppList' },
  { key: 'sys_instance', label: '应用系统', api: 'loadSysInstanceList' }
]

export default {
  components: {
    BaseSignPage
  },
  watch: {
    chooseData: {
      deep: true,
      async handler () {
        this.loadActionPlane(this.actionCate)
        this.currentAuthorities = this.packAuthorities()
      }
    },
    actionCate: {
      deep: true,
      async handler (cate) {
        if (cate) {
          this.loadActionPlane(cate)
        }
      }
    },
    actionSelected: {
      deep: true,
      handler (v) {
        this.packInAuthorities(v, this.actionCate)
      }
    }
  },
  computed: {
    cates () {
      return cates
    },
    actionUserAuths () {
      let key = this.actionCate.key
      let auths = this.chooseData ? this.currentAuthorities.find(auth => auth.objType === key) : null
      return auths ? auths.ids : []
    },
    actionValuesShow () {
      let authIds = this.actionSelected.map(v => v.key)
      let search = this.searchKey
      // !authIds.length 控制取反实现 当前已选项目不再展示在checkbox中
      return !authIds.length && !search ? this.actionValues : this.actionValues.filter(v => {
        if (authIds.includes(v.key)) {
          return false
        } else if (search) {
          return v.label.indexOf(search) > -1
        } else {
          return true
        }
      })
    },
    tableActions: {
      get () {
        return [{
          label: '删除',
          key: 'delete'
        },
        {
          label: '修改',
          key: 'edit'
        },
        {
          label: '权限',
          key: 'auth'
        },
        {
          label: '重置密码',
          key: 'reset_pwd'
        }]
      }
    },
    formParms: {
      get () {
        let data = [{
          type: 'input',
          label: '姓名',
          key: 'name',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '账号',
          fmDisabled: this.chooseData && true,
          key: 'phone',
          check: {
            required: true
          }
        },
        {
          type: 'multipleChoose',
          label: '角色',
          key: 'roleIds',
          selectDatas: this.sysRoles,
          check: {
            required: true
          }
        }]
        return data
      }
    },
    titleMenus: {
      get () {
        return [{
          key: 'add',
          label: '新增'
        }]
      }
    },
    sysRoles: {
      get () {
        return this.$store.getters.roleList
      }
    },
    columnList: {
      get () {
        return [{
          field: 'name',
          title: '姓名'
        },
        {
          field: 'phone',
          title: '账号'
        },
        {
          field: 'roles',
          title: '角色',
          searchType: 'select',
          searchSelectDatas: this.sysRoles,
          searchFun: (data, parm) => {
            let have = false
            data.roles.forEach((item) => {
              if (item.id === parm) {
                have = true
              }
            })
            return have
          },
          render: (h, rowData) => {
            let data = []
            rowData.roles.forEach((item) => {
              data.push(item.name)
            })
            return h('div', data.join(','))
          }
        }]
      }
    }
  },
  created () {
    this.loadData()
    this.$store.dispatch('loadRoleList')
  },
  methods: {
    async loadActionPlane (cate) {
      this.authLoading = true
      this.actionValues = await this.$store.dispatch(cate.api)
      this.actionSelected = this.actionValues.filter(v => {
        return this.actionUserAuths.includes(v.key)
      })
      this.authLoading = false
    },
    async authModelSubmit () {
      this.authRunning = true
      this.$nextTick(async () => {
        await ucenterRequest.updateAuthorities(this.chooseData.id, this.currentAuthorities)
        this.authModalShow = false
        this.actionSelected = []
        this.actionValues = []
        this.currentAuthorities = []
        this.authRunning = false
        this.loadData()
      })
    },
    packInAuthorities (authorities, cate) {
      if (!this.currentAuthorities.length || !this.currentAuthorities.find(v => v.objType === cate.key)) {
        this.currentAuthorities.push({
          objType: cate.key,
          ids: []
        })
      }
      this.currentAuthorities = this.currentAuthorities.map(v => {
        if (v.objType === cate.key) {
          v.ids = authorities.map(v => v.key)
        }
        return v
      })
    },
    packAuthorities (authorities) {
      authorities = authorities || this.chooseData.authorities

      return this.cates.map(v => {
        return {
          objType: v.key, ids: authorities.filter(auth => {
            return v.key === auth.objType
          }).map(auth => auth.objId)
        }
      })
    },
    selectAll () {
      this.actionSelected = this.actionValues.slice(0)
    },
    selectOff () {
      let keys = this.actionSelected.map(v => v.key)
      this.actionSelected = this.actionValues.filter(v => {
        return !keys.includes(v.key)
      })
    },
    onRemove (value) {
      this.actionSelected = this.actionSelected.filter(v => {
        return v.key !== value.key
      })
    },
    valuesChange (values) {
      this.actionSelected = this.actionValues.filter(v => {
        return values.includes(v.key)
      })
    },
    tableAction (data) {
      this.chooseData = data.data
      if (data.action === 'edit') {
        this.openDialog = true
      } else if (data.action === 'delete') {
        this.delData()
      } else if (data.action === 'reset_pwd') {
        this.resetPassword()
      } else if (data.action === 'auth') {
        this.authModalShow = true
      }
    },
    async resetPassword () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定重置用户 ' + this.chooseData.name + ' 的密码吗?'})
      if (result) {
        resetPassword(this.chooseData.id).then(() => {
          this.$notice.success({
            title: '系统提示',
            desc: '密码重置成功'
          })
        })
      }
    },
    async delData () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除用户 ' + this.chooseData.name + ' 吗?'})
      if (result) {
        request.del(this.chooseData.id).then(() => {
          this.$notice.info({
            title: '系统提示',
            desc: '用户已删除'
          })
          this.loadData()
        })
      }
    },
    formSubmit (data) {
      data.carTeamIds = data.carTeamIds || []
      if (this.chooseData) {
        request.update(this.chooseData.id, data).then(() => {
          this.$notice.success({
            title: '系统提示',
            desc: '用户修改完成'
          })
          this.loadData()
          this.openDialog = false
        })
      } else {
        request.add(data).then(() => {
          this.$notice.success({
            title: '系统提示',
            desc: '用户新增成功'
          })
          this.loadData()
          this.openDialog = false
        })
      }
    },
    countFun (data) {
      return data.length
    },
    countDataChange (data) {
      this.noteText = '总数:' + data
    },
    clickTitleMenu (parm) {
      this.chooseData = null
      if (parm === 'add') {
        this.openDialog = true
      }
    },
    loadData () {
      this.loading = true
      let parm = {}
      request.get(parm).then((data) => {
        data.forEach((item) => {
          item.roleIds = []
          item.carTeamIds = []
          item.roleNames = []
          item.roles.forEach((itemRole) => {
            item.roleIds.push(itemRole.id)
            item.roleNames.push(itemRole.name)
          })
          item.roleNames = item.roleNames.join(',')
        })
        this.dataList = data
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  },
  data () {
    return {
      dataList: [],
      noteText: '',
      loading: true,
      chooseData: null,
      openDialog: false,
      authLoading: false,
      authRunning: false,
      authModalShow: false,
      searchKey: null,
      actionCate: cates[0],
      actionValues: [],
      actionSelected: [],
      currentAuthorities: []
    }
  }
}
</script>
